export enum PropertyType {
    none = 0,
    address = 1,
    legalDescription = 2,
    legalDescriptionCondo = 3,
    rollNumber = 4,
    lincNumber = 5,
    titleNumber = 6,
    cpid = 7,
    community = 8,
    sdab = 9,
    quadrant = 10,
    blockProfile = 11,
    ats = 12,
    cityWide = 13,
    document = 14,
    lcsab = 15,
    ptweb = 16,
    //exit case
    default = 17
}

export enum PropertyMaxLength {
    rollNumber = 9,
    lincNumber = 10,
    cpid = 10,
    titleNumber = 12,
    default = 12
}
export enum PropertyMinLength {
    rollNumber = 8,
    lincNumber = 8,
    cpid = 8,
    titleNumber = 5,
    default = 10
}
export enum PropertyInformationSearchTypes {
    address = PropertyType.address,
    legalDescription = PropertyType.legalDescription,
    legalDescriptionCondo = PropertyType.legalDescriptionCondo,
    rollNumber = PropertyType.rollNumber,
    lincNumber = PropertyType.lincNumber,
    titleNumber = PropertyType.titleNumber,
    cpid = PropertyType.cpid
}
export enum WithDocumentNamePropertyType {
    none = PropertyType.none,
    ats = PropertyType.ats,
    community = PropertyType.community,
    quadrant = PropertyType.quadrant,
    blockProfile = PropertyType.blockProfile,
    cityWide = PropertyType.cityWide
}

export enum nonDownloadableProperty {
    lcsab = PropertyType.lcsab,
    sdab = PropertyType.sdab,
    default = PropertyType.default
}

export enum TaxPropertyAttributeName {
    propertyTaxInformation = 'Property_Tax_Information',
    propertyTaxStatementOfAccount = 'Property_Tax_Statement_of_Account',
    propertyTaxCertificateRequestByEmail = 'Property_Tax_Certificate',
}